import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col,ListGroup} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from "../components/seo"
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'


const manufacturingDesign =()=>{
    return(
    <Layout>
        <SEO title="Manufacturer Design" />
        <div className="homeAuto">
            <Image alt="home automation" filename="manufacturing-head"  />
            <div className="manufacturingImage">
                <h1 style={{fontWeight:"bold"}} className="manuH1">DESIGN & MANUFACTURING</h1>
                <h3 className="manuH3">Save costs and improve production.</h3>
                <p style={{fontSize:"18px"}} className="manuP">We’ll help you focus on your core business model so you don’t have to worry about manufacturing slips.</p>
            </div>
        </div>
        <div className="manufactureForm">
            <Container >
                <Row >
                    <Col sm={12} lg={6} className="mt-3">
                        <Image alt="home" filename="man1.png" />
                    </Col>
                    <Col sm={12} lg={6} className="homeImageText mt-3">
                        Trace Width Technology Solutions “Design & Manufacturing” services promotes the New Product Introduction (NPI) phase of the hardware product development. We work with you to evolve your hardware product development from design to manufacturing swiftly by contributing to new product launches, manufacturing, engineering, synchronous design engineering, production gradient, and even encompassing production administration services.</Col>
                </Row>
            </Container>
        </div>
      
        <Container >
            <div className="manuPara" style={{marginBottom:"20px"}}>
                Trace Width Technology Solutions’ exclusively practices hardware design and manufacturing offerings, encloses product perception, utility studies, hardware design including In-Circuit-Test design and user interfaces, amalgamation, prototyping, and recognition. Some of the key highlights of our hardware design and manufacturing conventions include –
            </div>
            <Row >
                <Col sm={12} lg={6} >
                    <Image alt="home" filename="manu-1.png" />
                </Col>
                <Col sm={12} lg={6} className="manuPara1">
                    <p>Our clients remain competitive by staying focused on their core competencies and sourcing their manufacturing design and interfacing needs to us.</p>
                    <p>As a team, we employ a design technique that takes into account the vivacious and often isochronal nature of product development while working jointly with you through the life-cycle in its entirety. We take pride in our inter mutual, client-consolidated approach, which involves you in all aspects and stages of your project. Our core competence is to acquaint clearly – combined with our potentiality to outgrow the practical and cost effective designs – certifies that your end product will always live up to your needs and conjectures.</p>
                </Col>
            </Row>
        </Container>
       
           
        <Container className="manuList">
            <Row>
                <h3 className="manuh3">Experience and Expertise:</h3>
                </Row>
                <Row >
                    <ListGroup variant="flush" className="listContent">
                        <ListGroup.Item className="manuListItem"><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp; &nbsp;Automation Solutions</ListGroup.Item>
                        <ListGroup.Item className="manuListItem"><FontAwesomeIcon icon={faAngleRight} size="1x" style={{fontSize:"22px"}}/> &nbsp; &nbsp;Biometric Scanning Security Systems</ListGroup.Item>
                        <ListGroup.Item className="manuListItem"><FontAwesomeIcon icon={faAngleRight} size="1x" style={{fontSize:"22px"}}/> &nbsp; &nbsp;Specialized Tablet PCs</ListGroup.Item>
                    </ListGroup>
                </Row>
        </Container>

        <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">Stop waiting and let’s start creating today!</div>
            <ContactForm  />
        </div>
    </Layout>
    );
}

export default manufacturingDesign;